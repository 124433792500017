import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const ProjectCard = ({ projectCardInfo }) => {
	const { title, subtitle, about, slug, tags } = projectCardInfo;

	const truncateByWords = (text, maxWords) => {
		const words = text.split(' ');

		if (words.length > maxWords) {
			return words.slice(0, maxWords).join(' ') + '...';
		}
		return text;
	};

	return (
		<Link
			className="project-link"
			to={`/projects/${slug}`}
			aria-label={`Read more about ${slug}`}>
			<article className="project-card">
				<h2 className="project-title">{title}</h2>
				<h3 className="project-subtitle">{subtitle}</h3>
				<p className="text-[16px] leading-6">
					{about && `${truncateByWords(documentToPlainTextString(about), 20)}`}
				</p>

				<div className="project-card-metadata">
					{tags && (
						<ul className="categories">
							{tags.map((tag, id) => {
								return (
									<li className="semi-14 category" key={id}>
										{tag}
									</li>
								);
							})}
						</ul>
					)}
					<p className="flex items-center text-[16px] gap-[4.4px] font-semibold mt-[1rem] lg:mt-0">
						Read more
						<AiOutlineArrowRight />
					</p>
				</div>
			</article>
		</Link>
	);
};

export default ProjectCard;
