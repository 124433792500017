import { useForm } from 'react-hook-form';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
import LoadingState from '../../components/LoadingState/LoadingState';
import { useGlobalContext } from '../../appContext';
import MapGL from './MapGL';

function Contact() {
    const { register, handleSubmit, formState: { errors, isSubmitting, isSubmitSuccessful }, reset } = useForm();

    const onSubmit = async (data) => {
        try {
            await fetch('/sendform', {
                method: 'POST',
                body: JSON.stringify(data)
            });
            reset();
        } catch (error) {
            console.error('Submission error:', error);
        }
    };

    const { homepageData, isLoading } = useGlobalContext();

    if (!homepageData || homepageData.length === 0) {
        return <LoadingState />;
    }
    const homeStatic = homepageData[0];

    if (isLoading) {
        return <LoadingState />;
    }

    return (
        <div className='contact'>
            <div className='contact-wrapper'>
                <div className='form-container'>
                    <h1 className='mb-4'>Contact the Lab</h1>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        id='contact-form'
                        name='contact'
                        method='post'
                    >
                        <input type='hidden' name='form-name' value='contact' />
                        <div className='name-email-subject'>
                            <fieldset>
                                <legend className='sr-only'>
                                    Please Enter Your Name
                                </legend>
                                <label className='semi-14' htmlFor='name'>
                                    Name
                                </label>
                                <input
                                    className='regular-14'
                                    id='name'
                                    name='fullName'
                                    type='text'
                                    placeholder='Your name'
                                    {...register('fullName', { required: true })}
                                />
                                {errors.message && <p className='text-box semi-14 error-message'>Name is required.</p>}

                            </fieldset>
                            <fieldset>
                                <legend className='sr-only'>
                                    Please Enter Your Email
                                </legend>
                                <label className='semi-14' htmlFor='email'>
                                    Email
                                </label>
                                <input
                                    className='regular-14'
                                    id='email'
                                    name='email'
                                    type='email'
                                    placeholder='email@address.com'
                                    {...register('email', { required: true })}
                                />
                                {errors.message && <p className='text-box semi-14 error-message'>Email is required.</p>}

                            </fieldset>
                            <fieldset>
                                <legend className='sr-only'>
                                    Please Select a Subject for Your Message
                                </legend>
                                <label className='semi-14' htmlFor='subject'>
                                    Subject
                                </label>
                                <select
                                    className='regular-14 default'
                                    name='subject'
                                    id='subject'
                                    {...register('subject', { required: true })}
                                    
                                >
                                    <option value='select a subject' disabled>
                                        Select a subject
                                    </option>
                                    <option value='study'>Study</option>
                                    <option value='working at the lab'>
                                        Working at the Lab
                                    </option>
                                    <option value='media inquiry'>
                                        Media inquiry
                                    </option>
                                    <option value='other'>Other</option>
                                </select>
                                {errors.message && <p className='text-box semi-14 error-message'>Subject is required.</p>}

                            </fieldset>
                        </div>
                        <div className='contact-message'>
                            <label className='semi-14' htmlFor='your-message'>
                                Your Message
                            </label>

                            <textarea
                                className='default regular-14'
                                name='subject'
                                id='your-message'
                                cols='30'
                                rows='8'
                                placeholder='Start typing your message...'
                                {...register('message', { required: true })}
                            />
                            {errors.message && <p className='text-box semi-14 error-message'>Message is required.</p>}
                        </div>
                        <div className='button-container'>
                            {isSubmitSuccessful ? (
                                <p className='text-box semi-14 form-submitted'>
                                    Thank you! Your message has been sent.
                                </p>
                            ) : (
                                <p className='text-box semi-14'>
                                    The information above is used solely to
                                    respond to your inquiry.
                                </p>
                            )}

                            <button
                                type='submit'
                                className='regular-caps'
                                disabled={isSubmitting}
                       
                            >
                                SEND
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
            <div className='contact-wrapper'>
                <div className='address-container'>
                    <address className='address'>
                        <div className='bold-18 address-name'>
                            Digital Society Lab
                        </div>
                        <div className='street-address'>
                            <p className='bold-18'>
                                {homeStatic?.streetAddress &&
                                    documentToReactComponents(homeStatic.streetAddress, options)}
                            </p>
                            <p className='bold-18'>{homeStatic.city}</p>
                            <p className='bold-18'>
                                <span>{homeStatic.province}</span>&nbsp;
                                <span>{homeStatic.postcode}</span>
                            </p>
                        </div>
                        <address className='contact-methods'>
                            <div className='bold-16' style={{'margin-bottom': '5px'}}>
                                {homeStatic.phoneNumber}
                            </div>

                            <div className='bold-16'>
                                <a href={`mailto: ${homeStatic.email}`}>
                                    {homeStatic.email}
                                </a>
                            </div>
                        </address>
                    </address>
                    {/* <div className='map'></div> */}
                    <div className='map'>
                        <MapGL />
                    </div>
                </div>
            </div>
            {/* <hr /> */}
        </div>
    );
}

export default Contact;
