import React, { useState } from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { options, getCategoryStyles } from '../../utils';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ModalLayout from '../../components/Modal/Modal';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import DefaultPlaceholderImg from '../../assets/default_dsl_placeholder.svg';

const NewsCard = ({ newsItem }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { title, subtitle, about, url, media, category } = newsItem;

	const truncateByWords = (text, maxWords) => {
		const words = text.split(' ');

		if (words.length > maxWords) {
			return words.slice(0, maxWords).join(' ') + '...';
		}
		return text;
	};

	return (
		<>
			<button
				onClick={() => setIsModalOpen(true)}
				className="group border flex flex-col justify-start border-[#DAD7C9] rounded-lg w-full max-w-[400px] lg:max-w-[320px] xl:max-w-[400px] mb-4 hover:bg-soft-black hover:border-black">
				<div className="rounded-t-lg bg-white border-b border-[#DAD7C9] group-hover:border-black">
					<img
						src={
							media?.fields.file.url
								? media?.fields.file.url
								: DefaultPlaceholderImg
						}
						alt={media?.fields.title}
						className={`w-full h-[300px] ${
							media?.fields.file.url ? 'object-cover' : 'object-fit'
						} rounded-t-[10px]`}
					/>
				</div>
				<article className="learn-more py-[42px] px-[32px] rounded-[10px] w-full text-left">
					<span
						className={`${getCategoryStyles(
							category
						)} font-bold px-[9px] py-[8.5px] text-[13px] rounded-[4px] uppercase`}>
						{category}
					</span>
					<h2 className="project-title mt-[16px] mb-[8px] group-hover:text-neutral-100">{title}</h2>
					{subtitle && <h3 className="project-subtitle group-hover:text-neutral-100">{subtitle}</h3>}
					<p className="regular-16 mt-[24px] group-hover:text-neutral-100">
						{about &&
							`${truncateByWords(documentToPlainTextString(about), 20)}`}
					</p>
				</article>
			</button>
			{isModalOpen && (
				<ModalLayout
					open={isModalOpen}
					setOpen={setIsModalOpen}
					media={media?.fields.file.url}>
					<div className="px-[40px] py-[24px] modal-content">
						<span
							className={`${getCategoryStyles(
								category
							)} font-bold px-[9px] py-[8.5px] text-[13px] rounded-[4px] uppercase`}>
							{category}
						</span>
						<h1 className="text-[20px] leading-[30px] mt-[16px] my-[8px]">{title}</h1>
						{subtitle && <h3 className="font-light text-[16px] mb-[24px]">{subtitle}</h3>}
						{about && documentToReactComponents(about, options)}
						{url && (
							<a
								href={url}
								className="learn-more text-[#1F53D9] inline-block inline-flex items-end uppercase text-[13px] mt-4"
								target="_blank"
								rel="noreferrer">
								<span>Learn more</span>
								<ArrowTopRightOnSquareIcon className="inline-block w-[18px] h-[18px] ml-1" />
							</a>
						)}
					</div>
				</ModalLayout>
			)}
		</>
	);
};

export default NewsCard;
