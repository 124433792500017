import {
	FaFacebookF,
	FaXTwitter,
	FaLinkedinIn,
	FaInstagram,
} from 'react-icons/fa6';

function Footer({ homeStatic }) {
	return (
		<footer className="footer">
			<div className="footer-wrapper">
				<div className="logo-media">
					<div className="media-container">
						<a
							href="https://twitter.com/DigSocietyLab"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Follow us on X">
							<FaXTwitter aria-hidden="true" size={16} />
							<span className="sr-only">Follow us on X</span>
						</a>
						<a
							href="https://www.facebook.com/DigSocietyLab"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Follow us on Facebook">
							<FaFacebookF aria-hidden="true" size={16} />
							<span className="sr-only">Follow us on Facebook</span>
						</a>
						<a
							href="https://www.linkedin.com/company/digitalsocietylab"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Follow us on LinkedIn">
							<FaLinkedinIn aria-hidden="true" size={18} />
							<span className="sr-only">Follow us on LinkedIn</span>
						</a>
						<a
							href="https://www.instagram.com/digitalsocietylab?igsh=NGppcHBzY2hyN256&utm_source=qr"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Follow us on instagram">
							<FaInstagram aria-hidden="true" size={18} />
							<span className="sr-only">Follow us on instagram</span>
						</a>
					</div>
				</div>
				<div className="copyright-terms">
					<p className="copyright text-[14px]">
						&copy;&nbsp;
						{`${new Date().getFullYear()} Digital Society Lab`}
					</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
