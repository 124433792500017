import React, { useEffect, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import { useGlobalContext } from '../../appContext';
import { NEWS } from '../../appConstants';
import LoadingState from '../../components/LoadingState/LoadingState';
import useContentful from '../../hooks/useContenful';
import NewsCard from './NewsCard';
import SelectLayout from '../../components/Select/Select';
import ComboboxLayout from '../../components/Combobox/Combobox';
import { sortDataByDate } from '../../utils';

const News = () => {
	const { newsTitle, newsBody, isLoading } = useGlobalContext();
	const [news, setNews] = useState(null);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [sortOrder, setSortOrder] = useState(null);
	const [filteredNews, setFilteredAndSortedNews] = useState([]);

	const { getCmsResponse } = useContentful();

	useEffect(() => {
		(async () => {
			try {
				const array = await getCmsResponse(NEWS);
				const sorted = sortDataByDate([...array], 'newest');
				array && setNews(sorted);
			} catch (error) {
				console.error('Failed to load the projects data', error);
			}
		})();
	}, []);

	useEffect(() => {
		const filtered =
			selectedCategories.length > 0
				? news?.filter((newsItem) => {
						return newsItem.category.some((cat) =>
							selectedCategories.some(
								(selectedCat) => selectedCat.toLowerCase() === cat.toLowerCase()
							)
						);
				  })
				: news;

		setFilteredAndSortedNews(filtered);
	}, [news, selectedCategories]);

	useEffect(() => {
		if (!sortOrder) return;
		const sorted = sortDataByDate([...filteredNews], sortOrder);
		setFilteredAndSortedNews(sorted);
	}, [sortOrder]);

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<main className="projects-main">
			<Banner title={newsTitle} info={newsBody} />

			<article className="wrapper">
				{!news ? (
					<h2 className="font-normal text-sm py-4">
						There are no news to display.
					</h2>
				) : (
					<>
						<div className="flex gap-4 flex-wrap">
							<ComboboxLayout
								data={news}
								selectedCategories={selectedCategories}
								setSelectedCategories={setSelectedCategories}
							/>
							<SelectLayout setSortOrder={setSortOrder} />
						</div>
						<div className="grid grid-cols-1 md:grid-cols-[repeat(2,max-content)] lg:grid-cols-[repeat(3,max-content)] gap-[1rem] py-[4rem] justify-start">
							{filteredNews?.map((newsItem) => {
								return <NewsCard key={newsItem.id} newsItem={newsItem} />;
							})}
						</div>
					</>
				)}
			</article>
		</main>
	);
};

export default News;
